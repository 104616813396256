@import '../../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/variables';
@import '../../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/mixins';

// Service Status pane
.pane-wcm-its-active-service-alerts{
  text-align: center;
  padding-bottom: 1.5rem;

  .pane-title{
    color: $wcm-white;
    background-color: $wcm-red;
    padding: 1rem 0;

    &::before{
      @include fontello();
      content: '\e812 ';
      margin-right: 1rem;
    }
  }

  .alerts{
    border-bottom: 1px solid $wcm-border-gray;
    padding: .75rem 0;

    .field-title{
      font-family: $wcm-bold;
      font-size: $font-size-h4;
    }
    .field-updated{
      .field-label{
        font-weight: bold;
      }
    }
  }

  .footer{
    margin-top: 1.5rem;
  }

}